<template>
  <form
    ref="form"
    @submit.prevent="submit"
  >
    <c-title>Zusammenfassung und Erklärungen</c-title>

    <c-form-fieldset
      v-if="reportData"
      class="mt-8 mb-8"
      legend="Zusammenfassung Antragsformular"
    >
      <c-summary-list>
        <c-summary-list-item title="Name">
          {{ reportData.name }}
        </c-summary-list-item>
        <c-summary-list-item title="Rechtsform">
          {{ reportData.legal_form }}
        </c-summary-list-item>
        <c-summary-list-item title="Ansprechpartner*in">
          {{ reportData.contact_person }}
        </c-summary-list-item>
        <c-summary-list-item title="Anschrift">
          {{ reportData.address }}
          <br>
          {{ reportData.postal_code }} {{ reportData.city }}
        </c-summary-list-item>
        <c-summary-list-item title="Telefonnummer">
          {{ reportData.contact_number }}
        </c-summary-list-item>
        <c-summary-list-item title="E-Mail-Adresse">
          {{ reportData.email }}
        </c-summary-list-item>
        <c-summary-list-item title="Website">
          {{ reportData.website }}
        </c-summary-list-item>
        <c-summary-list-item title="Titel">
          {{ reportData.project_title }}
        </c-summary-list-item>
        <c-summary-list-item title="Beschreibung des Vorhabens">
          {{ reportData.message_text }}
        </c-summary-list-item>
        <c-summary-list-item title="Beginn, Zeitraum">
          {{ reportData.start_period }}
        </c-summary-list-item>
        <c-summary-list-item title="Antragssumme">
          {{ reportData.application_amount }}
        </c-summary-list-item>
        <c-summary-list-item title="Kostenplan">
          <div v-if="reportData.cost_plan_file.name">
            Datei: {{ reportData.cost_plan_file.name }}
          </div>
        </c-summary-list-item>
        <c-summary-list-item title="Vitae Projektbeteiligte">
          <div v-if="reportData.vitae_project_participants.name">
            Datei: {{ reportData.vitae_project_participants.name }}
          </div>
        </c-summary-list-item>
      </c-summary-list>
    </c-form-fieldset>

    <c-form-fieldset
      legend="Erklärungen"
      class="mb-8"
    >
      <c-form-checkbox
        v-model="fields.consent_privacy"
        class="mb-5"
        label="Ich erkläre mich mit der Speicherung und Verarbeitung der angegebenen Daten gemäß Art. 6 Abs. 1b) Datenschutz-Grundverordnung (DSGVO) einverstanden und habe <a href='https://www.kulturstiftung-hh.de/fileadmin/user_upload/Was_wir_foerdern/ArtConnects/ArtConnectsDatenschutz.pdf' target='_blank'>die Information gemäß Art. 13 DSGVO</a> zur Kenntnis genommen."
        required
      />

      <c-form-checkbox
        v-model="fields.consent_mention"
        class="mb-5"
        label="Ich erkläre mich einverstanden, dass im Falle einer Förderung ggf. die Förderdaten (Name, Thema des geförderten Vorhabens, Höhe der Zuwendung) in Pressemeldungen, Stiftungspublikationen und im Internet veröffentlicht werden."
        required
      />

      <c-form-checkbox
        v-model="fields.consent_publication"
        class="mb-5"
        label="Ich versichere die Richtigkeit und Vollständigkeit der Angaben."
        required
      />
    </c-form-fieldset>

    <c-form-text
      v-model="fields.signature_and_date"
      :errors="errors.signature_and_date"
      class="mb-8"
      label="Vollständiger Name und Datum"
      help="Dieses Formular wurde digital erstellt und gilt als unterschrieben von:"
      maxlength="400"
      required
    />

    <c-message
      v-if="unhandledError.status"
      type="error"
      class="mb-8"
    >
      <p v-if="unhandledError.message">
        {{ unhandledError.message }}
      </p>

      <template v-else>
        <p class="mb-3">
          Bei der Übertragung des Antrags kam es zu einem Fehler. Bitte probieren Sie es zu einem späteren Zeitpunkt erneut.
        </p>
        <p class="mb-3">
          Prüfen Sie bitte auch, ob die hochgeladenen Dateien zu groß sind. Die maximale Dateigröße beträgt 5 MB.
        </p>
        <p class="mb-3">
          Sollte das Problem weiterhin bestehen, wenden Sie sich mit der folgenden Fehlermeldung an uns:
        </p>
        <p>
          {{ unhandledError.status }} {{ unhandledError.fallback }}
        </p>
      </template>
    </c-message>

    <c-button
      type="button"
      class="mr-5"
      color="secondary"
      @click="previous"
    >
      Zurück
    </c-button>

    <c-button :disabled="(isLoading || !published)">
      Antragsformular abschicken
    </c-button>

    <transition name="fade">
      <c-loading v-if="isLoading" />
    </transition>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

import CLoading from '../components/CLoading.vue'
import CMessage from '../components/CMessage.vue'
import CSummaryList from '../components/CSummaryList.vue'
import CSummaryListItem from '../components/CSummaryListItem.vue'

import { postRequest } from '../api.js'
import CFormCheckbox from '../components/CFormCheckbox.vue'

export default {
  name: 'VRequestStep1',

  components: {
    CLoading,
    CMessage,
    CSummaryList,
    CSummaryListItem,
    CFormCheckbox
  },

  extends: BRequestStep,

  data () {
    return {
      isLoading: false,
      unhandledError: {
        status: null,
        message: '',
        fallback: ''
      },
      fields: {
        consent_privacy: false,
        consent_publication: false,
        consent_mention: false,
        signature_and_date: ''
      }
    }
  },

  computed: {
    step: () => 1,
    id: () => 'summaryTerms',
    steps () {
      return this.$store.state.steps
    },
    reportData () {
      return this.steps[0]?.fields
    },
    salutation () {
      return {
        f: 'Frau',
        m: 'Herr',
        n: false
      }[this.reportData.salutation]
    },
    published () {
      return this.$store.state.publishedInfo.published
    }
  },

  methods: {
    formatTextarea (text) {
      // First split on double line breaks to create <p>.
      // Second split on single line breaks to create <br>.
      return text
        .split('\n\n')
        .map(p => `<p class="mb-3">${p}</p>`)
        .join('')
        .split('\n')
        .join('<br>')
    },
    async submit () {
      this.isLoading = true
      this.unhandledError.status = null
      this.unhandledError.message = ''
      this.unhandledError.fallback = ''

      this.save({ completed: true })

      const form = new FormData()

      for (const step of this.steps) {
        for (const field in step.fields) {
          form.append(field, step.fields[field])
        }
      }

      if (
        this.fields.consent_privacy &&
        this.fields.consent_publication &&
        this.fields.consent_mention
      ) {
        form.append('has_accepted_conditions', true)
      } else {
        this.unhandledError.status = 10
        this.unhandledError.fallback = 'Bitte akzeptieren Sie alle Erklärungen.'
        this.isLoading = false
        return
      }

      try {
        await postRequest(form)
        this.$router.push({ name: 'thank-you' })
        this.$store.commit('reset')
      } catch (error) {
        const response = error.response

        // A JSON response is Django's form validation.
        if (response.headers['content-type'] === 'application/json') {
          this.$store.commit('errors', response.data)
          this.$router.push({ name: 'request-step-0' })
          return
        }

        // Everything else is nginx's stuff.
        const message = {
          413: 'Ihre hochgeladenen Dateien sind zu groß. Bitte wählen Sie kleinere Dateien aus und probieren es erneut.'
        }[response?.status]

        if (message) {
          this.unhandledError.message = message
        } else {
          this.unhandledError.fallback = response?.statusText ?? error.message
        }

        this.unhandledError.status = response?.status ?? error.name
        this.isLoading = false
      }
    }
  }
}
</script>
